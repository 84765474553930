<template>
  <section class="h-screen">
    <div class="w-11/12 md:w-1/3 m-auto">
      <img class="" src="@/assets/security-icon.svg" alt="Security icon" />
      <div>
        <h2 class="text-center text-3xl text-white mt-12">
          MULTI-FACTOR AUTHENTICATION
        </h2>
        <div v-if="recoveryCodes.totp.recovery.length === 0">
          <p class="text-center text-white mt-5">
            Scan the QR code below with any two-factor authentication app on
            your phone, and then enter the code it generates into the field
            below. Some compatible apps are Google Authenticator and 2FA
            Authenticator.
          </p>
          <QRCode class="p-2" :content="qrContent"></QRCode>
          <OTPForm @submitEvent="handleSubmit" :isRegister="true" />
        </div>
        <div v-else class="alert alert-info">
          <p>
            Save this codes in a save place. This codes are used in case you
            loose your authentication app.
          </p>
          <p>Recovery Codes</p>
          <ul>
            <li v-for="(code, index) in recoveryCodes" :key="index">
              <label>{{ code }}</label>
            </li>
          </ul>
          <button class="w-full" @click="goHome">Continue</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import QRCode from "@/components/general/QRCode.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import OTPForm from "@/components/auth/OTPForm.vue";

const store = useStore();
const router = useRouter();
const qrContent = ref("");

const getQr = async (getAccessToken) =>
  store.dispatch("user/totpDevice", getAccessToken);
const submitTOTPCode = async (totpCode) =>
  store.dispatch("user/verifyTOTP", { totpCode });
const accessToken = computed(() => store.getters["user/getAccessToken"]);
const recoveryCodes = computed(() => store.getters["user/getMFA"]);

onMounted(async () => {
  await updateQR();
});

async function handleSubmit(code) {
  const response = await submitTOTPCode(code);

  if (!response.success) {
    //TODO: Show error message
  }

  return router.push({ name: "Home" });
}

async function updateQR() {
  const response = await getQr(accessToken);

  if (!response.totpLink) {
    return router.push({ name: "SignUp" });
  }

  qrContent.value = response.totpLink;
}
</script>

<style scoped>
img {
  display: block;
  margin: 2rem auto;
  height: 40px;
  width: 40px;
}

@media (min-width: 1400px) {
  img {
    height: 40px;
    width: 40px;
  }

  #content {
    margin-top: 1rem;
  }
}
</style>
