<template>
  <form @submit.prevent="submitTOTPCode">
    <p class="text-white my-8">6-digit code</p>
    <div class="flex justify-center">
      <input
        v-for="i in numberInputs"
        :ref="
          (el) => {
            input[i] = el;
          }
        "
        :key="i"
        type="text"
        :id="i"
        maxlength="1"
        placeholder="-"
        autocomplete="off"
        @keyup="clickEvent"
        required
      />
    </div>
    <button class="mt-12 w-full text-color" type="submit" id="verify-btn">
      Verify
    </button>
  </form>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";

const code = ref("");
const input = ref([]);
const DELETE_KEY = ref(8);

const emit = defineEmits(["submitEvent"]);

const props = defineProps({
  numberInputs: {
    type: Number,
    default: 6,
  },
  isRegister: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  input.value[1].focus();
});

function submitTOTPCode() {
  emit("submitEvent", code.value);
  return;
}

function clickEvent(event) {
  const keyEvent = event || window.event;
  const id = parseInt(keyEvent.target.id);
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;

  if (isNumeric(keyEvent.key) && code.value.length <= props.numberInputs - 1) {
    addDigit(id, keyEvent.key);
  } else if (charCode === DELETE_KEY.value) {
    deleteDigit(id);
  } else {
    selectFocusInput(id);
    keyEvent.preventDefault();
  }
}

function addDigit(id, digit) {
  code.value += digit;

  if (id === props.numberInputs) {
    return submitTOTPCode();
  }

  selectFocusInput(id + 1);
}

function deleteDigit(id) {
  const inputId = id === 6 && code.value.length === 6 ? id : id - 1;
  code.value = code.value.slice(0, -1);
  selectFocusInput(inputId);
}

function selectFocusInput(id) {
  if (id > 0) {
    input.value[id].focus();
    input.value[id].value = "";
  }
}

function isNumeric(charCode) {
  const regex = /^[0-9]+$/;
  return regex.test(charCode);
}
</script>

<style scoped>
input {
  width: 40px;
  height: 40px;
  margin-left: 1rem;
  text-align: center;
}

input:focus {
  border: 3px solid #1d2e48;
}

#verify-btn {
  background-color: #edfe86;
  border: none;
  padding: 11px;
  width: 100%;
  margin-top: 1rem;
}

@media (min-width: 746px) {
  input {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1400px) {
  #verify-btn {
    margin-top: 2rem;
  }
}
</style>
