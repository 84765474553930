<template>
  <div class="mt-8 flex justify-center">
    <QRCode
      :value="this.content"
      :size="this.size"
      :reder-as="this.render_as"
      :margin="this.margin"
      :background="this.background"
      :foreground="this.foreground"
    ></QRCode>
  </div>
</template>

<script>
import QRCode from "qrcode.vue";

export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 170,
    },
    render_as: {
      type: String, //("canvas" | "svg")
      default: "svg",
    },
    margin: {
      type: Number,
      default: 2.5,
    },
    background: {
      type: String,
      default: "#FFFFFF",
    },
    foreground: {
      type: String,
      default: "#000000",
    },
  },
  components: {
    QRCode,
  },
};
</script>
